nav > div > ul > a {
  margin: 0 5%;
  padding: 5%;
  border-radius: 10%;
  text-decoration: none;
}
nav > div > ul > a:hover, nav > div > ul > a.navbar-item.active { 
  background-color: #0084ca !important; 
  text-decoration: none;
}

nav > div > ul > a  > li {
  color: white;
}

.navbar {
  height: 100px;
  background-color: #214456;
}

.nav-item {
  list-style: none;
}

.bm-item-list  > a {
  color: rgb(190, 196, 226); 
  text-decoration: none;
  padding: 5px;
}
.bm-item:hover {
  background-color: #0084ca!important;
  color: rgb(190, 196, 226); 
}

/*Logo*/
.logo {
  width:50%
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fafafa;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #10a2f1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #172f3c;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.divider0 {
  border-top: 0 !important;
}

.Home, .About, .Contact {
  margin-bottom: 60px;
}

/*About*/
div.About > div > div > ul > li, div.About > div > div > ol > li {
  margin-left: 20px;
}

/*Card*/
.card {
  width: 236pt!important; 
  text-align: center;
  margin-right: auto !important;
  margin-left: auto !important;
  margin-bottom: 4%;  
}
.card-body {
  color: #151515!important;
}

.btn-tr {
  --bs-btn-color: #fff!important;
  --bs-btn-bg: #172f3c!important;
  --bs-btn-border-color: #172f3c!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #172f3c;
  --bs-btn-hover-border-color: #172f3c!important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #172f3c;
  --bs-btn-active-border-color: #172f3c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #172f3c;
  --bs-btn-disabled-border-color: #172f3c;
}

/*copyright*/
.copyright-info {
  line-height: 15px;
}

.Footer {
  background: #dcdcdc;
  bottom: 0;
  height: 60px;
  margin: 30px 0 0;
  padding: 20px;
  width: 100%;
  position: fixed;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .bm-burger-button {
    display: block;
  }
  .container-fluid{
    display: none!important;
  }
  .navbar {
    position: sticky!important;
    top: 0;
    z-index: 100;
    background: url(../images/logo-tr-nutrition.png) no-repeat center center #234658;
    background-size: contain;
  }
}